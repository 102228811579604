<template>
  <b-card>
    <!-- Modal -->
    <b-modal
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Save"
      header-close-content=""
      :ok-disabled="isDisableBtn"
      @ok="save"
      @cancel="close"
    >
      <b-container fluid>
        <b-row>
          <!-- Title -->
          <b-col cols="6">
            <b-form-group id="input-title">
              <label for="titleInput">Title: <span class="text-danger">*</span></label>
              <b-form-input
                id="titleInput"
                name="title"
                v-model="editedItem.title"
                type="text"
                aria-describedby="input-title-feedback"
                data-vv-as="title"
                :class="{ input: true, 'is-danger': errors.has('title') }"
                v-validate="{ required: true }"
              ></b-form-input>
              <span v-show="errors.has('title')" class="is-danger-text position-relative">{{
                errors.first('title')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Handler  -->

          <b-col cols="6">
            <b-form-group id="input-handler">
              <label for="handlerInput">Handler: <span class="text-danger">*</span></label>
              <b-form-input
                id="handlerInput"
                :disabled="!isCreated"
                name="handler"
                v-model="editedItem.handler"
                type="text"
                aria-describedby="input-handler-feedback"
                data-vv-as="handler"
                :class="{ input: true, 'is-danger': errors.has('handler') }"
                v-validate="{ required: true }"
              ></b-form-input>
              <span v-show="errors.has('handler')" class="is-danger-text position-relative">{{
                errors.first('handler')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Content Format   -->

          <b-col cols="6">
            <b-form-group id="input-content_format">
              <label for="content_formatInput">Content Format: <span class="text-danger">*</span></label>
              <b-form-input
                id="content_formatInput"
                name="content_format"
                v-model="editedItem.content_format"
                type="text"
                aria-describedby="input-content_format-feedback"
                data-vv-as="content_format"
                :class="{
                  input: true,
                  'is-danger': errors.has('content_format')
                }"
                v-validate="{ required: true }"
              ></b-form-input>
              <span v-show="errors.has('content_format')" class="is-danger-text position-relative">{{
                errors.first('content_format')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Type   -->
          <b-col cols="6">
            <b-form-group id="input-type">
              <label for="typeInput">Type: <span class="text-danger">*</span></label>
              <b-form-input
                id="typeInput"
                name="type"
                v-model="editedItem.type"
                type="number"
                aria-describedby="input-type-feedback"
                data-vv-as="type"
                :class="{
                  input: true,
                  'is-danger': errors.has('type')
                }"
                v-validate="{ required: true, min: 1, max: 256 }"
              ></b-form-input>
              <span v-show="errors.has('type')" class="is-danger-text position-relative">{{
                errors.first('type')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Description   -->
          <b-col cols="6">
            <b-form-group id="input-description">
              <label for="descriptionInput">Description: <span class="text-danger">*</span></label>
              <b-form-input
                id="descriptionInput"
                name="description"
                v-model="editedItem.description"
                type="text"
                aria-describedby="input-description-feedback"
                data-vv-as="description"
                :class="{ input: true, 'is-danger': errors.has('description') }"
                v-validate="{ required: true }"
              ></b-form-input>
              <span v-show="errors.has('description')" class="is-danger-text position-relative">{{
                errors.first('description')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Target Url Format   -->
          <b-col cols="6">
            <b-form-group id="input-target_url_format">
              <label for="target_url_formatInput">Target Url Format :</label>
              <b-form-input
                id="target_url_formatInput"
                name="target_url_format  "
                v-model="editedItem.target_url_format"
                type="text"
                aria-describedby="input-target_url_format-feedback"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <!-- status -->
          <b-col cols="6">
            <b-form-group id="input-status" label="Status:" label-for="Status">
              <b-form-select v-model="filter_status" :options="statusList" name="Status">
                <template v-slot:first>
                  <b-form-select-option :value="null">-- Please select Status --</b-form-select-option>
                </template></b-form-select
              >
            </b-form-group>
          </b-col>

          <!-- input Image Url -->
          <b-col cols="6">
            <b-form-group id="input-img-url" label="Image URL:" label-for="img-url" aria-describedby="input-image-help">
              <b-form-input
                id="img-url"
                name="imageUrl"
                v-model="editedItem.image_url"
                type="text"
                placeholder="Image URL..."
              ></b-form-input>
              <b-form-text id="input-image-help">Enter exist image link or upload new image file.</b-form-text>
            </b-form-group>
          </b-col>

          <!-- choose Image file -->
          <b-col cols="6">
            <b-form-group id="input-img-file" label="Image File:" label-for="img-file">
              <b-form-file
                v-model="editedItem.image_file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Notification Action List</h4>
      </b-col>
      <!-- Filter Title -->
      <b-col cols="4">
        <b-form-group id="input-title-filter" label="Title" label-for="title-filter">
          <b-form-input
            id="title-filter"
            v-model="filter_title"
            type="text"
            placeholder="Search title..."
            @input="onFilterName"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <!-- Filter Active -->
      <b-col cols="2">
        <b-form-group id="input-status-filter" label="Status" label-for="status-filter">
          <b-form-select id="status-filter" v-model="filter_status" :options="statusList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>
      <b-col cols="6" class="btnAdd">
        <b-button variant="primary" class="float-right" @click="addItem">Add</b-button>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table"
    >
      <template v-slot:cell(image_preview)="item">
        <!-- {{ item.value }} -->
        <img :src="item.value[0].src" style="height: 50px" @click="openGallery(item.item.id)" />
        <LightBox
          :ref="'lightbox_' + item.item.id"
          :media="item.value"
          :show-light-box="false"
          :show-thumbs="false"
        ></LightBox>
      </template>
      <template v-slot:cell(status)="item">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success">Active</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0 && item.item.deleted_at" variant="danger">Deleted</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0" variant="warning">Inactive</b-badge>
      </template>
      <template v-slot:cell(actions)="item">
        <v-icon class="text-success" @click="editItem(item.item)">mdi-pencil</v-icon>
        <v-icon class="text-danger" @click="deleteItem(item.item)">mdi-delete</v-icon>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form-group
          id="input-per-page"
          label="Rows per page: "
          label-for="per-page"
          label-cols="8"
          style="margin-right: 20px; margin-bottom: 0"
        >
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            style="width: 65px"
            @change="fetchData"
          ></b-form-select>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import ApiService from '@/common/api.service.js';
import LightBox from 'vue-image-lightbox';
import _ from 'lodash';
import axios from 'axios';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
  name: 'Noti Action',
  components: {
    LightBox
  },
  data() {
    return {
      isCreated: true,
      search: '',
      isBusy: false,
      filter_title: '',
      filter_category: null,
      filter_status: null,
      statusList: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Inactive' }
      ],
      headers: [
        { text: 'Title', key: 'title' },
        { text: 'Handler', key: 'handler' },
        { text: 'Content Format', key: 'content_format' },
        { text: 'Image Url', key: 'image_preview' },
        { text: 'Description', key: 'description' },
        { text: 'Target Url Format', key: 'target_url_format' },
        { text: 'Type', key: 'type' },
        { text: 'Status', key: 'status', class: 'text-center' },
        { text: 'Actions', key: 'actions', class: 'text-center' }
      ],
      // Modal data
      items: [],
      categoryAdsList: [],
      modalTitle: '',
      // loading: false,
      rows: 0,
      perPage: 10,
      currentPage: 1,
      editedItem: {
        title: '',
        handler: '',
        content_format: '',
        description: '',
        image_file: null,
        target_url_format: '',
        type: '',
        status: ''
      },
      dialog: false,
      editedIndex: -1,
      editor: ClassicEditor,
      editorConfig: {
        language: 'de'
      }
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    }
  },
  computed: {
    isDisableBtn() {
      return (
        this.errors.has('title') ||
        !this.editedItem.title ||
        this.errors.has('handler ') ||
        !this.editedItem.handler ||
        this.errors.has('content_format ') ||
        !this.editedItem.content_format ||
        this.errors.has('type ') ||
        !this.editedItem.type ||
        this.errors.has('description') ||
        !this.editedItem.description
      );
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Notification Action' }]);
  },
  methods: {
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500),
    openGallery(id) {
      this.$refs[`lightbox_${id}`].showImage(0);
    },
    fetchData() {
      this.loading = true;
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      ApiService.get(
        'notification-actions',
        `?title=${this.filter_title}&status=${
          this.filter_status == null ? '' : this.filter_status
        }&limit=${limit}&offset=${offset}`
      )
        .then((resp) => {
          const notiRes = resp;
          this.items = notiRes.data.data.notiActionList.map((ads) => {
            return {
              ...ads,
              image_preview: [{ thumb: ads.image_url, src: ads.image_url }]
            };
          });
          this.rows = notiRes.data.data.total;
          this.isBusy = false;
        })
        .catch((err) => {
          this.$bvToast.toast(`${err}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },
    addItem: function () {
      this.isCreated = true;
      this.dialog = true;
      this.modalTitle = 'Add Notification Action';
    },
    editItem: function (item) {
      this.isCreated = false;
      this.dialog = true;
      this.modalTitle = 'Update Notification Action';
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item
      };
      this.filter_status = item.status;
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      if (confirm('Are you sure you want to delete this item?')) {
        ApiService.delete(`notification-actions/${item.id}`)
          .then((response) => {
            if (response.status === 204) {
              this.editedIndex = this.items.indexOf(item);
              this.editedItem = {
                ...item
              };
              this.editedItem.deleted_at = new Date();
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.$bvToast.toast('Notification Action deleted!', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
              this.items[index].status = 0;
            }
          })
          .catch((errors) => {
            this.$bvToast.toast(`${errors}`, {
              title: null,
              variant: 'danger',
              autoHideDelay: 3000,
              noCloseButton: true
            });
          });
      }
    },
    close() {
      this.dialog = false;
      this.editedItem = {
        title: '',
        handler: '',
        content_format: '',
        description: '',
        image_file: null,
        target_url_format: '',
        type: '',
        status: ''
      };
      this.editedIndex = -1;

      this.filter_status = null;
    },
    save(bvModalEvt) {
      // Cancel auto close
      bvModalEvt.preventDefault();
      // Validate required fields
      // Get new brand/cate/vendor name for data-table
      this.editedItem.status = this.filter_status;
      let formData = new FormData();
      for (const key in this.editedItem) {
        if (this.editedItem.hasOwnProperty(key)) {
          const element = this.editedItem[key];
          if (element !== '' && element !== null) {
            formData.append(key, element);
          }
        }
      }
      if (this.editedItem.image_url === '') {
        formData.append('image_url', '');
      }
      if (this.editedIndex > -1) {
        // Update Noti
        axios({
          method: 'PUT',
          url: `notification-actions/${this.items[this.editedIndex].id}`,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' }
        })
          .then((response) => {
            if (response.status === 204) {
              this.editedItem.status = this.filter_status;
              this.editedItem.image_preview = [
                {
                  thumb: this.editedItem.image_url,
                  src: this.editedItem.image_url
                }
              ];
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.close();
              this.$bvToast.toast('Notification action updated successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.$bvToast.toast('errors', {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      } else {
        axios({
          method: 'POST',
          url: 'notification-actions',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' }
        })
          .then((response) => {
            if (response.data.status) {
              this.items.unshift({
                ...response.data.data,
                image_preview: [
                  {
                    thumb: response.data.data.image_url,
                    src: response.data.data.image_url
                  }
                ]
              });
              this.close();
              this.$bvToast.toast('Notification action added successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.$bvToast.toast('errors', {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      }
    }
  }
};
</script>
